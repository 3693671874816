<template>
  <manager></manager>
</template>

<script>
import manager from "./manager";
export default {
  name: "add",
  components: {
    manager
  },
  async mounted() {

  },
}
</script>

<style scoped>

</style>
